import { Link, PageProps } from 'gatsby';
import React, { FC } from 'react';
import Lottie from 'react-lottie-player';
import styled from 'styled-components';
import { Layout } from '~/components/Layout';
import { SEO } from '~/components/SEO';
import squirrel from '~/data/squirrel.json';

const Pad = styled.div`
  min-height: 100vh;
`;

const Squirrel = styled.div`
  position: absolute;
  height: 80vh;
  top: 0;
  width: 100%;
`;

const Page: FC<PageProps> = () => {
  return (
    <Layout>
      <SEO title="404🐿️🐿️🐿️" />
      <Pad>
        <Squirrel>
          <Link to="/">
            <Lottie play animationData={squirrel} title="nom nom nom" />
          </Link>
        </Squirrel>
      </Pad>
    </Layout>
  );
};

export default Page;
